import React from "react";
import "./cookieNotice.scss";
import { useDispatch } from "react-redux";
import FEEDS from "../../../feeds";
import { getCP, CPSuccess } from "../header/commonPageSlice";
import fetch_json_action_creator from "../../utils/fetch";
import _ from "lodash";

const CookieNotice = (props) => {
  const dispatch = useDispatch();

  const cookieSubmit = async () => {
    let postCookieNotice = FEEDS.ROUTE.COMMON.COOKIE_NOTICE.SUBMIT;
    dispatch(getCP());
    let postResponse = await fetch_json_action_creator(
      postCookieNotice,
      "POST",
      {
        cookie_accepted: true,
      }
    );
    
    if (!_.isEmpty(postResponse) && postResponse.authenticated === false) {
      window.location = "/login?e=" + btoa("sessionTimedOut");
    }
    if (postResponse && postResponse.cookie_accepted) {
      window.location = "/home?s=" + btoa("cookieSubmitted");
    }
    dispatch(CPSuccess());
  };

  return props &&
    props.data &&
    props.data.data &&
    props.data.data.cookie_popup ? (
    <div className="cookie-notice">
      <div className="description">
        {props.data.data.cookie_popup.description}
      </div>
      <div className="cookie-notice-links">
        <nav className="navbar navbar-expand">
          <div className="navbar-collapse">
            <ul className="navbar-nav">
              {props.data.data.cookie_popup.buttonList.cookie_notice && (
                <li className="nav-item">
                  <a
                    className="nav-link"
                    target={
                      props.data.data.cookie_popup.buttonList.cookie_notice
                        .target
                    }
                    href={
                      props.data.data.cookie_popup.buttonList.cookie_notice.url
                    }
                  >
                    {props.data.data.cookie_popup.buttonList.cookie_notice.text}
                  </a>
                </li>
              )}
              {props.data.data.cookie_popup.buttonList.privacy_notice && (
                <li className="nav-item">
                  <a
                    className="nav-link"
                    target={
                      props.data.data.cookie_popup.buttonList.privacy_notice
                        .target
                    }
                    href={
                      props.data.data.cookie_popup.buttonList.privacy_notice.url
                    }
                  >
                    {
                      props.data.data.cookie_popup.buttonList.privacy_notice
                        .text
                    }
                  </a>
                </li>
              )}
            </ul>
          </div>
        </nav>
      </div>
      <div className="cookie-accept-button">
        {props.data.data.cookie_popup.buttonList.continue_to_site && (
          <button onClick={() => cookieSubmit()}>
            {props.data.data.cookie_popup.buttonList.continue_to_site.text}
          </button>
        )}
      </div>
    </div>
  ) : null;
};

export { CookieNotice as default };
