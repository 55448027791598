import { fetch } from "whatwg-fetch";

const environment = process.env.NODE_ENV;

export default function fetch_json_action_creator(
  fetch_action,
  method = "GET",
  data
) {
  // POST method not working in dev environment. So, navigating all request to GET only for dev
  if (environment === "development") {
    method = "GET";
    data = null;
  }

  // To form URL params for GET method
  let url = fetch_action;

  if (method === "GET" && data) {
    let params = Object.keys(data)
      .map(function (key) {
        return key + "=" + data[key];
      })
      .join("&");
    url = url + "?" + params;

    data = null;
  }

  let csrfToken = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");

  return fetch(url, {
    method, // *GET, POST, PUT, DELETE, etc.
    mode: environment === "development" ? "no-cors" : "same-origin", // no-cors, cors, *same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, same-origin, *omit
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-CSRF-TOKEN": csrfToken,
    },
    redirect: "follow", // manual, *follow, error
    referrer: "no-referrer", // no-referrer, *client
    body: data && JSON.stringify(data), // body data type must match "Content-Type" header
  })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        console.log("Server Error: "+res.status + " - " + res.statusText);
      }
    })
    .then((res) => {
      return new Promise((resolve) => resolve(res));
    })
    .catch(function (ex) {
      console.log("parsing failed: ", ex);
    });
}
