import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./home.scss";
import {
  homeSelector,
  fetchHome,
  getHome,
  getHomeAcceptSuccess,
  addAcceptedCardId,
} from "./homeSlice";
import { fetchCP } from "../../app/common/header/commonPageSlice";
import Title from "../../app/common/title/title";
import _ from "lodash";
import Grid from "../../app/common/grid/grid";
import { prepareGridData } from "../../app/utils/helpers";
import GlobalSpinner from "../../app/utils/spinner";
import GlobalAlert from "../../app/utils/alert/alert";
import ReactModal from "react-modal";
import FEEDS from "../../feeds";
import fetch_json_action_creator from "../../app/utils/fetch";
import SSN from "./ssn";

const Home = () => {
  const dispatch = useDispatch();
  const { homeData, loading } = useSelector(homeSelector);
  const [modalIsOpen, setIsOpen] = useState(false);

  if (!_.isEmpty(homeData) && homeData.authenticated === false) {
    window.location = "/login?e=" + btoa("sessionTimedOut");
  }

  const updateSort = () => {
    console.log("updatesort");
  };
  const reFetch = () => {
    console.log("refetch");
  };

  const acceptCard = async (id) => {
    let postAcceptCard = FEEDS.ROUTE.HOME.HOME_ACCEPT;
    dispatch(addAcceptedCardId(id));
    dispatch(getHome());
    let postResponse = await fetch_json_action_creator(postAcceptCard, "POST", {
      id: id,
    });
    if (!_.isEmpty(postResponse) && postResponse.authenticated === false) {
      window.location = "/login?e=" + btoa("sessionTimedOut");
    }

    if (
      postResponse &&
      postResponse.openModal === false &&
      postResponse.successMsg
    ) {
      dispatch(getHomeAcceptSuccess(postResponse));
      window.location = "/home?s=" + btoa("cardAccepted");
    }

    if (postResponse && postResponse.openModal === true) {
      dispatch(getHomeAcceptSuccess(postResponse));
      setIsOpen(true);
    }
  };

  if (!_.isEmpty(homeData) && !_.isEmpty(homeData.data)) {
    var banner = homeData.data.banner;
    var rewardDetails = homeData.data.rewardDetails;
    var gridData = homeData.data.rewardDetailsGrid;
    var notAvailable = homeData.data.notAvailable;
    var pageProps = homeData.data.pagination;
    var headers = homeData.data.headers;
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "auto",
      backgroundColor: "white",
      color: "black",
      border: "none",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  let source = null;
  if (gridData && gridData.length > 0) {
    source = prepareGridData(
      headers,
      gridData,
      pageProps,
      {
        isLastgrid: false,
        details: {},
      },
      {
        handleSorting: updateSort,
        handlePagination: reFetch,
        handleCard: acceptCard,
      },
      "static"
    );
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    dispatch(fetchHome());
    dispatch(fetchCP());
  }, []); // eslint-disable-line

  return (
    <>
      {homeData && homeData.data ? (
        <div className="home-page">
          <div className="banner">
            <img src={banner.img} alt={banner.alt} className="responsive" />
          </div>
          <GlobalAlert data={homeData} />
          <Title
            data={{
              title: homeData.data.title,
              description: homeData.data.description,
            }}
          />

          <div className="reward-details">
            <div className="reward-details-page">
              <div className="row">
                <div className="col-12 reward-title-container">
                  <div className="reward-title">{rewardDetails.title}</div>
                </div>
              </div>

              <div className="row time-card-container">
                <div className="col-lg-6 col-md-12 time-block">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 time-img">
                      <img
                        src={rewardDetails.block1Image.img}
                        alt={rewardDetails.block1Image.alt}
                        className="responsive"
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 time-text">
                      <div className="time-text-title">
                        {rewardDetails.block1TextTitle}
                      </div>
                      <div className="time-text-des">
                        {rewardDetails.block1Text}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12 card-block">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 card-img">
                      <img
                        src={rewardDetails.block2Image.img}
                        alt={rewardDetails.block2Image.alt}
                        className="responsive"
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 card-text">
                      <div className="card-text-title">
                        {rewardDetails.block2TextTitle}
                      </div>
                      <div className="card-text-des">
                        {rewardDetails.block2Text}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 block3">
                  {rewardDetails.block3Text}
                </div>
              </div>
            </div>
          </div>

          <div className="reward-grid-container">
            <div className="reward-grid">
              {gridData && gridData.length > 0 ? (
                <Grid source={source} />
              ) : (
                <div className="not-available">{notAvailable}</div>
              )}
            </div>
          </div>
        </div>
      ) : null}
      {loading && <GlobalSpinner />}
      <ReactModal
        isOpen={modalIsOpen}
        // onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
      >
        <SSN closeModal={closeModal} />
      </ReactModal>
    </>
  );
};

export { Home as default };
