import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import CookieNotice from "./cookieNotice";

const CookieModal = (props) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "auto",
      backgroundColor: "#FFFFFF",
      border: "none",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  useEffect(() => {
    if (
      props &&
      props.data &&
      props.data.authenticated === true &&
      props.data.cookie_accepted === false &&
      props.data.registration_completed === true
    ) {
      setIsOpen(true);
    }
  }, [props]);

  return props &&
    props.data &&
    props.data.data &&
    props.data.data.cookie_popup ? (
    <ReactModal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
      <CookieNotice data={props.data} />
    </ReactModal>
  ) : null;
};

export { CookieModal as default };
