import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./register1.scss";
import {
  registerSelector,
  fetchRegister,
  postRegisterSuccess,
  increaseRegisterStep,
  storePostData,
  getRegister,
} from "./registerSlice";
import { useForm } from "react-hook-form";
import FormBuilder from "../../app/common/formBuilder";
import Title from "../../app/common/title/title";
import _ from "lodash";
import FEEDS from "../../feeds";
import { useNavigate } from "react-router-dom";
import fetch_json_action_creator from "../../app/utils/fetch";
import GlobalSpinner from "../../app/utils/spinner";
import GlobalAlert from "../../app/utils/alert/alert";

const Register = () => {
  const dispatch = useDispatch();
  const { registerData, postData, loading } = useSelector(registerSelector);
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(fetchRegister());
  }, [dispatch]);

  var postResponse = undefined;
  var postRegisterPage = "";
  var formFields = null;
  var submitButton = null;
  var loginMessage = null;
  var loginButton = null;
  var registerFormInfo = null;
  const onSubmit = async (data, e) => {
    postRegisterPage = FEEDS.ROUTE.REGISTER.SUBMIT1;
    dispatch(storePostData(data));
    dispatch(getRegister());
    postResponse = await fetch_json_action_creator(
      postRegisterPage,
      "POST",
      data
    );
    dispatch(postRegisterSuccess(postResponse));

    if (
      !_.isEmpty(postResponse) &&
      _.isEmpty(postResponse.validationErrors) &&
      postResponse.redirect
    ) {
      dispatch(increaseRegisterStep());
    }
  };

  if (!_.isEmpty(registerData) && !_.isEmpty(registerData.data)) {
    formFields = registerData.data.formFields;
    submitButton = registerData.data.buttonList.submit;
    loginMessage = registerData.data.loginMessage;
    loginButton = registerData.data.buttonList.login;
    registerFormInfo = registerData.data.registerFormInfo;
  }

  const routeChange = (path) => {
    return navigate(path);
  };

  return (
    <>
      {registerData && registerData.data ? (
        <div className="register-page">
          <GlobalAlert data={registerData} />
          {postData && <GlobalAlert data={postData} />}
          <Title
            data={{
              title: registerData.data.title,
              description: registerData.data.description,
            }}
          />
          <div className="form-info mx-auto">
            {registerFormInfo && registerFormInfo}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mx-auto form-container">
              {formFields ? (
                <FormBuilder
                  fieldSection={formFields}
                  validation={{
                    register: register,
                    error: errors,
                    serverError: postData,
                  }}
                />
              ) : null}
            </div>
            <div className="text-center register-button">
              {submitButton ? (
                <input
                  type={submitButton.type}
                  name={submitButton.key}
                  value={submitButton.text}
                  className="btn"
                />
              ) : null}
            </div>
          </form>
          <div className="bottom-login-link mx-auto">
            {loginMessage}
            {loginButton && (
              <button
                className="login-link"
                onClick={() => routeChange(loginButton.url)}
              >
                {" "}
                <span>{loginButton.text}</span>
              </button>
            )}
          </div>
        </div>
      ) : null}
      {loading && <GlobalSpinner />}
    </>
  );
};

export { Register as default };
