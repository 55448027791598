import React from "react";

const EventButton = ({ text, clickEvent }) => {
  return clickEvent ? (
    <button className="default-button" onClick={(e) => clickEvent(e)}>
      {text}
    </button>
  ) : (
    <button className="default-button">{text}</button>
  );
};

export { EventButton as default };
