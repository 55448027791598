import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./footer.scss";
import { CPSelector, fetchCP } from "../header/commonPageSlice";
import {
  loginSelector,
  getLogout,
  logoutSuccess,
} from "../../../features/login/loginSlice";
import { getHomeSuccess } from "../../../features/home/homeSlice";
import { getProfileSuccess } from "../../../features/profile/profileSlice";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import GlobalSpinner from "../../utils/spinner";
import FEEDS from "../../../feeds";
import fetch_json_action_creator from "../../utils/fetch";
import { adminRoutes } from "../../utils/helpers";

const Footer = () => {
  const dispatch = useDispatch();
  const { CPData, loading } = useSelector(CPSelector);
  const { loader } = useSelector(loginSelector);
  const navigate = useNavigate();

  useEffect(() => {
    let a = window.location.href;
    if (!a.includes("/logout")) {
      dispatch(fetchCP());
    }
  }, [dispatch]);

  var buttonList = [];
  if (
    !_.isEmpty(CPData) &&
    !_.isEmpty(CPData.data) &&
    !_.isEmpty(CPData.data.footer) &&
    !_.isEmpty(CPData.data.footer.buttonList)
  ) {
    buttonList = CPData.data.footer.buttonList;
  }

  const routeChange = async (button) => {
    if (button.url === "/logout") {
      const getLogoutPage = FEEDS.ROUTE.COMMON.LOGOUT;
      dispatch(getLogout());
      const response = await fetch_json_action_creator(getLogoutPage);

      if (!_.isEmpty(response) && response.redirect) {
        dispatch(getHomeSuccess(null));
        dispatch(getProfileSuccess(null));
        dispatch(logoutSuccess(response));
        return navigate(button.url);
      }
    } else {
      if (button.key === "privacy-notice" || button.key === "cookie-notice") {
        window.open(button.url, "_blank");
      } else {
        return navigate(button.url);
      }
    }
  };

  return (
    <>
      {!_.isEmpty(CPData) && !_.isEmpty(CPData.data) ? (
        <footer>
          <div className="row">
            <div className="footer-top">
              <div className="ft-container">
                <p>
                  <span
                    className="paragraph"
                    dangerouslySetInnerHTML={{
                      __html: CPData.data.footer.topText,
                    }}
                  ></span>
                  <span
                    className="paragraph2"
                    dangerouslySetInnerHTML={{
                      __html: CPData.data.footer.topTextMail,
                    }}
                  ></span>
                </p>
              </div>
              <div className="footer-menu">
                <nav className="navbar navbar-expand">
                  <div className="collapse navbar-collapse" id="navbarText">
                    <ul className="navbar-nav">
                      {CPData.authenticated === true &&
                        buttonList &&
                        adminRoutes.indexOf(window.location.pathname) > -1 &&
                        buttonList.map((button) => {
                          if (
                            !(window.location.pathname.indexOf(button.url) >= 0)
                          ) {
                            return (
                              <li key={button.key} className="nav-item">
                                <button
                                  className="nav-link"
                                  onClick={() => routeChange(button)}
                                >
                                  {button.text}
                                </button>
                              </li>
                            );
                          } else {
                            return null;
                          }
                        })}
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="fb-container">
                <p
                  dangerouslySetInnerHTML={{
                    __html: CPData.data.footer.bottomText,
                  }}
                ></p>
              </div>
            </div>
          </div>
        </footer>
      ) : null}
      {loading && <GlobalSpinner />}
      {loader && <GlobalSpinner />}
    </>
  );
};

export { Footer as default };
