import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./register2.scss";
import {
  registerSelector,
  getRegister,
  getRegister2Success,
  getRegister2Failure,
  postRegister2Success,
} from "./registerSlice";
import { decreaseRegisterStep } from "./registerSlice";
import { useForm } from "react-hook-form";
import FormBuilder from "../../app/common/formBuilder";
import Title from "../../app/common/title/title";
import _ from "lodash";
import FEEDS from "../../feeds";
import { useNavigate } from "react-router-dom";
import fetch_json_action_creator from "../../app/utils/fetch";
import GlobalSpinner from "../../app/utils/spinner";
import GlobalAlert from "../../app/utils/alert/alert";

const Register2 = () => {
  const dispatch = useDispatch();
  const { register2Data, register1UserData, post2Data, loading } =
    useSelector(registerSelector);
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const getRegister2Page = FEEDS.ROUTE.REGISTER.GET_PAGE_DEFINITION2;

    async function fetchRegister2() {
      dispatch(getRegister());
      try {
        const response = await fetch_json_action_creator(
          getRegister2Page,
          "POST",
          register1UserData
        );
        await dispatch(getRegister2Success(response));
      } catch (error) {
        dispatch(getRegister2Failure());
      }
    }

    fetchRegister2();
  }, [dispatch]); // eslint-disable-line

  var postResponse = undefined;
  var postRegisterPage = "";
  const onSubmit = async (data, e) => {
    postRegisterPage = FEEDS.ROUTE.REGISTER.SUBMIT2;
    dispatch(getRegister());
    postResponse = await fetch_json_action_creator(
      postRegisterPage,
      "POST",
      data
    );
    dispatch(postRegister2Success(postResponse));

    if (_.isEmpty(postResponse.validationErrors) && postResponse.redirect) {
      reset();
      window.location = postResponse.redirect + "?s=" + btoa("registerSuccess");
    }
  };

  if (!_.isEmpty(register2Data) && !_.isEmpty(register2Data.data)) {
    var formFields = register2Data.data.formFields.set1;
    var formFields2 = register2Data.data.formFields.set2;
    var passwordInfo = register2Data.data.passwordInfo;
    var loginMessage = register2Data.data.loginMessage;
    var submitButton = register2Data.data.buttonList.submit;
    var backButton = register2Data.data.buttonList.back;
    var loginButton = register2Data.data.buttonList.login;
    var registerFormInfo = register2Data.data.registerFormInfo;
  }

  const routeChange = (path) => {
    return navigate(path);
  };

  return (
    <>
      {register2Data && register2Data.data ? (
        <div className="register2-page">
          <GlobalAlert data={register2Data} />
          {post2Data && <GlobalAlert data={post2Data} />}
          <Title
            data={{
              title: register2Data.data.title,
              description: register2Data.data.description,
            }}
          />
          <div className="form-info mx-auto">
            {registerFormInfo && registerFormInfo}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mx-auto form-container">
              {formFields ? (
                <FormBuilder
                  fieldSection={formFields}
                  validation={{
                    register: register,
                    error: errors,
                    serverError: post2Data,
                  }}
                />
              ) : null}
            </div>
            <div className="form-info mx-auto">{passwordInfo && passwordInfo}</div>
            <div className="mx-auto form-container form-set2">
              {formFields2 ? (
                <FormBuilder
                  fieldSection={formFields2}
                  validation={{
                    register: register,
                    error: errors,
                    serverError: post2Data,
                  }}
                />
              ) : null}
            </div>
            <div className="form-container mx-auto">
              <div className="row">
                <div className="col-6 register2-back-button">
                  {backButton ? (
                    <button
                      target={backButton.target}
                      className="btn"
                      onClick={() => dispatch(decreaseRegisterStep())}
                    >
                      {backButton.text}
                    </button>
                  ) : null}
                </div>
                <div className="col-6 register2-button">
                  {submitButton ? (
                    <input
                      type={submitButton.type}
                      name={submitButton.key}
                      value={submitButton.text}
                      className="btn"
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </form>

          <div className="bottom-login-link mx-auto">
            {loginMessage}
            {loginButton && (
              <button
                className="login-link"
                onClick={() => routeChange(loginButton.url)}
              >
                {" "}
                <span>{loginButton.text}</span>
              </button>
            )}
          </div>
        </div>
      ) : null}
      {loading && <GlobalSpinner />}
    </>
  );
};

export { Register2 as default };
