import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./ssn.scss";
import {
  login1Selector,
  fetchLogin,
  postLoginSuccess,
  getLogin,
  addSSNInput,
  addSSNInputConfirm,
} from "./ssnSlice";
import { removeAcceptedCardId, homeSelector } from "../home/homeSlice";
import _ from "lodash";
import { useForm } from "react-hook-form";
import FEEDS from "../../feeds";
import fetch_json_action_creator from "../../app/utils/fetch";
import Title from "../../app/common/title/title";
import GlobalSpinner from "../../app/utils/spinner";
import GlobalAlert from "../../app/utils/alert/alert";

const SSN = (props) => {
  const dispatch = useDispatch();
  const { loginSSNData, postData, loading, ssnInput, ssnInputConfirm } =
    useSelector(login1Selector);
  const { acceptedCardId } = useSelector(homeSelector);

  const [ssn, setSSN] = useState({
    ssn11: "---",
    ssn12: "--",
    ssn13: "----",
    ssn21: "---",
    ssn22: "--",
    ssn23: "----",
  });

  const [color1, setColor1] = useState({ color: "#c2c2c2" });
  const [color2, setColor2] = useState({ color: "#c2c2c2" });

  const ssn11Ref = useRef(null);
  const ssn12Ref = useRef(null);
  const ssn13Ref = useRef(null);
  const ssn21Ref = useRef(null);
  const ssn22Ref = useRef(null);
  const ssn23Ref = useRef(null);
  const ssn11hRef = useRef(null);
  const ssn12hRef = useRef(null);
  const ssn21hRef = useRef(null);
  const ssn22hRef = useRef(null);
  const ssn1Ref = useRef(null);
  const ssn2Ref = useRef(null);
  const social_security_numberRef = useRef(null);
  const social_security_number_confirmationRef = useRef(null);

  const [consentChecked, setConsentChecked] = useState(false); 
  function handleChange(e) {
    setConsentChecked(e.target.checked);
  }

  if (!_.isEmpty(loginSSNData) && loginSSNData.authenticated === false) {
    window.location = "/login?e=" + btoa("sessionTimedOut");
  }

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(fetchLogin());
  }, []); // eslint-disable-line

  function closeMe() {
    dispatch(getLogin());
    dispatch(postLoginSuccess({}));
    dispatch(addSSNInput(null));
    dispatch(addSSNInputConfirm(null));
    setTimeout(function () {
      props.closeModal();
    }, 1000);
  }

  var postResponse = undefined;
  var postSSNPage = "";
  const onSubmit = async (data, e) => {
    postSSNPage = FEEDS.ROUTE.HOME.SSN_SUBMIT;
    data.id = acceptedCardId;
    data.social_security_number = ssnInput;
    data.social_security_number_confirmation = ssnInputConfirm;
    data.ssn_consent = consentChecked;

    dispatch(getLogin());
    postResponse = await fetch_json_action_creator(postSSNPage, "POST", data);
    if (!_.isEmpty(postResponse) && postResponse.authenticated === false) {
      window.location = "/login?e=" + btoa("sessionTimedOut");
    }

    if (
      !_.isEmpty(postResponse) &&
      _.isEmpty(postResponse.validationErrors) &&
      postResponse.redirect
    ) {
      dispatch(postLoginSuccess(postResponse));
      dispatch(removeAcceptedCardId());
      reset();
      window.location = "/home?s=" + btoa("ssnVerified");
    }
    if (!_.isEmpty(postResponse) && !_.isEmpty(postResponse.validationErrors)) {
      dispatch(postLoginSuccess(postResponse));
    }
  };

  const formatSSN1 = (e) => {
    clearSSN1();

    if (e.key === "Tab") {
      setTimeout(() => {
        focusOutSSN1();
        clearSSN2();
        if (ssn.ssn21 < 3) {
          ssn21Ref.current.focus();
        } else if (ssn.ssn21 >= 3 && ssn.ssn22 < 2) {
          ssn22Ref.current.focus();
        } else {
          ssn23Ref.current.focus();
        }
      }, 500);
    } else if (e.key === "Backspace" || e.key === "Delete") {
      clearSSN1(true);
      dispatch(addSSNInput(null));
      social_security_numberRef.current.value = null;
    } else if (e.key === "Home") {
      e.preventDefault();
    } else {
      if (ssn.ssn11.length < 3) {
        ssn11Ref.current.focus();
      } else if (ssn.ssn11.length === 3 && ssn.ssn12.length < 2) {
        ssn12Ref.current.focus();
        ssn11hRef.current.innerHTML = "-";
      } else if (
        ssn.ssn11.length === 3 &&
        ssn.ssn12.length === 2 &&
        ssn.ssn13.length < 5
      ) {
        if (ssn.ssn11 !== "---" && ssn.ssn12 !== "--" && ssn.ssn13 !== "----") {
          ssn13Ref.current.focus();
          ssn12hRef.current.innerHTML = "-";
        }
      }
      let val = ssn.ssn11 + "-" + ssn.ssn12 + "-" + ssn.ssn13;
      if (ssn.ssn11 === "---" && ssn.ssn12 === "--" && ssn.ssn13 === "----") {
        val = null;
      }
      social_security_numberRef.current.value = val;
      dispatch(addSSNInput(val));
    }
  };

  const formatSSN2 = (e) => {
    clearSSN2();

    if (e.key === "Shift") {
      setTimeout(() => {
        focusOutSSN2();
        clearSSN1();
        if (ssn.ssn11 < 3) {
          ssn11Ref.current.focus();
        } else if (ssn.ssn11 >= 3 && ssn.ssn12 < 2) {
          ssn12Ref.current.focus();
        } else {
          ssn13Ref.current.focus();
        }
      }, 500);
    } else if (e.key === "Backspace" || e.key === "Delete") {
      clearSSN2(true);
      social_security_number_confirmationRef.current.value = null;
      dispatch(addSSNInputConfirm(null));
    } else if (e.key === "Home") {
      e.preventDefault();
    } else {
      if (ssn.ssn21.length < 3) {
        ssn21Ref.current.focus();
      } else if (ssn.ssn21.length === 3 && ssn.ssn22.length < 2) {
        ssn22Ref.current.focus();
        ssn21hRef.current.innerHTML = "-";
      } else if (
        ssn.ssn21.length === 3 &&
        ssn.ssn22.length === 2 &&
        ssn.ssn23.length < 5
      ) {
        if (ssn.ssn21 !== "---" && ssn.ssn22 !== "--" && ssn.ssn23 !== "----") {
          ssn23Ref.current.focus();
          ssn22hRef.current.innerHTML = "-";
        }
      }
      let val = ssn.ssn21 + "-" + ssn.ssn22 + "-" + ssn.ssn23;
      if (ssn.ssn21 === "---" && ssn.ssn22 === "--" && ssn.ssn23 === "----") {
        val = null;
      }
      social_security_number_confirmationRef.current.value = val;
      dispatch(addSSNInputConfirm(val));
    }
  };

  const focusOutSSN1 = () => {
    if (ssn.ssn11 === "" && ssn.ssn12 === "" && ssn.ssn13 === "") {
      setSSN((prevSSN) => ({ ...prevSSN, ssn11: "---" }));
      setSSN((prevSSN) => ({ ...prevSSN, ssn12: "--" }));
      setSSN((prevSSN) => ({ ...prevSSN, ssn13: "----" }));
      ssn11Ref.current.value = "---";
      ssn12Ref.current.value = "--";
      ssn13Ref.current.value = "----";
      ssn11hRef.current.innerHTML = "-";
      ssn12hRef.current.innerHTML = "-";
      ssn11Ref.current.setSelectionRange(0, 0);
      setColor1({ color: "#c2c2c2" });
    }
  };

  const focusOutSSN2 = () => {
    if (ssn.ssn21 === "" && ssn.ssn22 === "" && ssn.ssn23 === "") {
      setSSN((prevSSN) => ({ ...prevSSN, ssn21: "---" }));
      setSSN((prevSSN) => ({ ...prevSSN, ssn22: "--" }));
      setSSN((prevSSN) => ({ ...prevSSN, ssn23: "----" }));
      ssn21Ref.current.value = "---";
      ssn22Ref.current.value = "--";
      ssn23Ref.current.value = "----";
      ssn21hRef.current.innerHTML = "-";
      ssn22hRef.current.innerHTML = "-";
      ssn21Ref.current.setSelectionRange(0, 0);
      setColor2({ color: "#c2c2c2" });
    }
  };

  const clearSSN1 = ($clearSSN1 = false) => {
    if (
      (ssn.ssn11 === "---" && ssn.ssn12 === "--" && ssn.ssn13 === "----") ||
      $clearSSN1 === true
    ) {
      setSSN((prevSSN) => ({ ...prevSSN, ssn11: "" }));
      setSSN((prevSSN) => ({ ...prevSSN, ssn12: "" }));
      setSSN((prevSSN) => ({ ...prevSSN, ssn13: "" }));
      ssn11Ref.current.value = "";
      ssn12Ref.current.value = "";
      ssn13Ref.current.value = "";
      ssn11hRef.current.innerHTML = "";
      ssn12hRef.current.innerHTML = "";
      ssn11Ref.current.focus();
      setColor1({ color: "#000" });
      ssn11Ref.current.setSelectionRange(0, 0);
    }
  };

  const clearSSN2 = ($clearSSN2 = false) => {
    if (
      (ssn.ssn21 === "---" && ssn.ssn22 === "--" && ssn.ssn23 === "----") ||
      $clearSSN2 === true
    ) {
      setSSN((prevSSN) => ({ ...prevSSN, ssn21: "" }));
      setSSN((prevSSN) => ({ ...prevSSN, ssn22: "" }));
      setSSN((prevSSN) => ({ ...prevSSN, ssn23: "" }));
      ssn21Ref.current.value = "";
      ssn22Ref.current.value = "";
      ssn23Ref.current.value = "";
      ssn21hRef.current.innerHTML = "";
      ssn22hRef.current.innerHTML = "";
      ssn21Ref.current.focus();
      setColor2({ color: "#000" });
      ssn21Ref.current.setSelectionRange(0, 0);
    }
  };

  if (!_.isEmpty(loginSSNData) && !_.isEmpty(loginSSNData.data)) {
    var submitButton = loginSSNData.data.buttonList.submit;
    var formFields1 = loginSSNData.data.formFields[0];
    var formFields2 = loginSSNData.data.formFields[1];
    var formFields3 = loginSSNData.data.formFields[2];
  }

  return (
    <>
      {loginSSNData && loginSSNData.data ? (
        <div className="ssn-page mx-auto">
          <div className="close-button-container">
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={() => closeMe()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <GlobalAlert data={loginSSNData} />
          <GlobalAlert data={postData} />
          <Title
            data={{
              title: loginSSNData.data.title,
              description: loginSSNData.data.description,
            }}
          />

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="ssn-form">
              {formFields3 && formFields3.key && (
                <div className="ssn-consent">
                  <label>
                    <input
                      id={formFields3.key}
                      type={formFields3.type}
                      onChange={handleChange}
                    />
                    <span className="consent-text">{formFields3.label}</span>
                  </label>

                  {!errors[formFields3.key] &&
                    postData &&
                    postData.validationErrors &&
                    postData.validationErrors[formFields3.key] && (
                      <p className="text-danger" id="server-error1">
                        {postData.validationErrors[formFields3.key]}
                      </p>
                    )}
                </div>
                
              )}

              {formFields1 && (
                <>
                  {formFields1.label && (
                    <label>
                      {formFields1.label}
                      {formFields1.required &&
                      formFields1.required !== "false" ? (
                        <sup className="text-danger"> *</sup>
                      ) : null}
                    </label>
                  )}
                  <div className="ssn1" ref={ssn1Ref} onClick={formatSSN1}>
                    <input
                      type="password"
                      name="ssn11"
                      id="ssn11"
                      maxLength="3"
                      onKeyUp={formatSSN1}
                      onClick={formatSSN1}
                      onBlur={focusOutSSN1}
                      onChange={(event) =>
                        setSSN((prevSSN) => ({
                          ...prevSSN,
                          ssn11: event.target.value,
                        }))
                      }
                      ref={ssn11Ref}
                      defaultValue={ssn.ssn11}
                      style={color1}
                    />
                    <span id="ssn11h" style={color1} ref={ssn11hRef}>
                      -
                    </span>
                    <input
                      type="password"
                      name="ssn12"
                      id="ssn12"
                      maxLength="2"
                      onKeyUp={formatSSN1}
                      onClick={formatSSN1}
                      onBlur={focusOutSSN1}
                      onChange={(event) =>
                        setSSN((prevSSN) => ({
                          ...prevSSN,
                          ssn12: event.target.value,
                        }))
                      }
                      ref={ssn12Ref}
                      defaultValue={ssn.ssn12}
                      style={color1}
                    />
                    <span id="ssn12h" style={color1} ref={ssn12hRef}>
                      -
                    </span>
                    <input
                      type="password"
                      name="ssn13"
                      id="ssn13"
                      maxLength="4"
                      onKeyUp={formatSSN1}
                      onClick={formatSSN1}
                      onBlur={focusOutSSN1}
                      onChange={(event) =>
                        setSSN((prevSSN) => ({
                          ...prevSSN,
                          ssn13: event.target.value,
                        }))
                      }
                      ref={ssn13Ref}
                      defaultValue={ssn.ssn13}
                      style={color1}
                    />
                  </div>
                  <input
                    id={formFields1.key}
                    className="form-control"
                    type={formFields1.type}
                    name={formFields1.key}
                    placeholder="&#9679;&#9679;&#9679;-&#9679;&#9679;-&#9679;&#9679;&#9679;&#9679;"
                    defaultValue={formFields1.value && formFields1.value}
                    {...register(formFields1.key, {})}
                    ref={social_security_numberRef}
                  />
                  {errors[formFields1.key] && (
                    <p className="text-danger">
                      {formFields1.label} is Required
                    </p>
                  )}
                  {!errors[formFields1.key] &&
                    postData &&
                    postData.validationErrors &&
                    postData.validationErrors[formFields1.key] && (
                      <p className="text-danger" id="server-error1">
                        {postData.validationErrors[formFields1.key]}
                      </p>
                    )}
                </>
              )}

              {formFields2 && (
                <>
                  {formFields2.label && (
                    <label>
                      {formFields2.label}
                      {formFields2.required &&
                      formFields2.required !== "false" ? (
                        <sup className="text-danger"> *</sup>
                      ) : null}
                    </label>
                  )}
                  <div className="ssn2" ref={ssn2Ref} onClick={formatSSN2}>
                    <input
                      type="password"
                      name="ssn21"
                      id="ssn21"
                      maxLength="3"
                      onKeyUp={formatSSN2}
                      onClick={formatSSN2}
                      onBlur={focusOutSSN2}
                      onChange={(event) =>
                        setSSN((prevSSN) => ({
                          ...prevSSN,
                          ssn21: event.target.value,
                        }))
                      }
                      ref={ssn21Ref}
                      defaultValue={ssn.ssn21}
                      style={color2}
                    />
                    <span id="ssn21h" style={color2} ref={ssn21hRef}>
                      -
                    </span>
                    <input
                      type="password"
                      name="ssn22"
                      id="ssn22"
                      maxLength="2"
                      onKeyUp={formatSSN2}
                      onClick={formatSSN2}
                      onBlur={focusOutSSN2}
                      onChange={(event) =>
                        setSSN((prevSSN) => ({
                          ...prevSSN,
                          ssn22: event.target.value,
                        }))
                      }
                      ref={ssn22Ref}
                      defaultValue={ssn.ssn22}
                      style={color2}
                    />
                    <span id="ssn22h" style={color2} ref={ssn22hRef}>
                      -
                    </span>
                    <input
                      type="password"
                      name="ssn23"
                      id="ssn23"
                      maxLength="4"
                      onKeyUp={formatSSN2}
                      onClick={formatSSN2}
                      onBlur={focusOutSSN2}
                      onChange={(event) =>
                        setSSN((prevSSN) => ({
                          ...prevSSN,
                          ssn23: event.target.value,
                        }))
                      }
                      ref={ssn23Ref}
                      defaultValue={ssn.ssn23}
                      style={color2}
                    />
                  </div>
                  <input
                    id={formFields2.key}
                    className="form-control"
                    type={formFields2.type}
                    name={formFields2.key}
                    placeholder="&#9679;&#9679;&#9679;-&#9679;&#9679;-&#9679;&#9679;&#9679;&#9679;"
                    defaultValue={formFields2.value && formFields2.value}
                    {...register(formFields2.key, {})}
                    ref={social_security_number_confirmationRef}
                  />
                  {errors[formFields2.key] && (
                    <p className="text-danger">
                      {formFields2.label} is Required
                    </p>
                  )}
                  {!errors[formFields2.key] &&
                    postData &&
                    postData.validationErrors &&
                    postData.validationErrors[formFields2.key] && (
                      <p className="text-danger" id="server-error2">
                        {postData.validationErrors[formFields2.key]}
                      </p>
                    )}
                </>
              )}

              <div className="ssn-button text-center">
                {submitButton ? (
                  <input
                    type={submitButton.type}
                    name={submitButton.key}
                    value={submitButton.text}
                    className="btn"
                  />
                ) : null}
              </div>
            </div>
          </form>
        </div>
      ) : null}
      {loading && <GlobalSpinner />}
    </>
  );
};

export { SSN as default };
