import { createSlice } from "@reduxjs/toolkit";
import fetch_json_action_creator from "../../app/utils/fetch";
import FEEDS from "../../feeds";

const initialState = {
  success: true,
  authenticated: false,
  register2Data: [],
  post2Data: [],
  loading: true,
  hasErrors: false,
};

export const register2Slice = createSlice({
  name: "register2Data",
  initialState,
  reducers: {
    getRegister2: (state) => {
      state.loading = true;
    },
    getRegister2Success: (state, { payload }) => {
      state.register2Data = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getRegister2Failure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    postRegister2Success: (state, { payload }) => {
      state.postData = payload;
      state.loading = false;
      state.hasErrors = false;
    },
  },
});

export const {
  getRegister2,
  getRegister2Success,
  getRegister2Failure,
  postRegister2Success,
} = register2Slice.actions;
export const register2Selector = (state) => state.register2Data;
export default register2Slice.reducer;

const getRegister2Page = FEEDS.ROUTE.REGISTER.GET_PAGE_DEFINITION2;

export function fetchRegister2() {
  return async (dispatch) => {
    dispatch(getRegister2());
    try {
      const response = await fetch_json_action_creator(getRegister2Page);
      await dispatch(getRegister2Success(response));
    } catch (error) {
      dispatch(getRegister2Failure());
    }
  };
}
