import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import EventButton from "../../utils/form/event-button";

const customStyles = {
  headRow: {
    style: {
      backgroundColor: "#FFFFFF", // override
      textTransform: "uppercase",
    },
  },
  rows: {
    style: {
      backgroundColor: "#FFFFFF",
      height: 'auto',
    },
    stripedStyle: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#F0F0F0",
      },
    },
  },
  headCells: {
    style: {
      padding: "1.2rem 0 1.1rem 2rem", // override
    },
  },
  cells: {
    style: {
      padding: "1.2rem 0 1.1rem 2rem", // override
    },
  },
};

const Grid = (source) => {
  const paginationData = source.source.paginationData;
  const lastRow = source.source.lastRow;
  const gridHeader = source.source.gridHeader;
  const gridData = source.source.gridData;
  const callbacks = source.source.callbacks;
  const staticPagination = source.source.staticPagination;

  const [response, setResponse] = useState(null);
  const [columns, setColumns] = useState(null);
  const [page, setPage] = useState(null);
  const [totalRows, setTotalRows] = useState(null);
  const [perPage, setPerPage] = useState(null);

  const serverPagination = staticPagination ? false : true;
  const navigate = useNavigate();

  useEffect(() => {
    setColumns(setColumn(gridData));
    setResponse(gridData); //To render local data for now
    setPage(paginationData ? parseInt(paginationData.page) : null);
    setTotalRows(paginationData ? parseInt(paginationData.total) : 0);
    setPerPage(paginationData ? parseInt(paginationData.per_page) : 10);
  }, []); // eslint-disable-line

  const setColumn = (data) => {
    let columnKeys = Object.keys(data[0]);
    var remaining = columnKeys.reduce(function (result, val) {
      if (val !== "id") {
        result.push(val);
      }
      return result;
    }, []);

    let prepareColumn = remaining.map((data, i) => {
      if (i === 1) {
        return {
          name: gridHeader[i].title || data,
          selector: (row) => "$" + row["amount"],
          sortable: false,
          width: '20%',
          center: 'yes',
        };
      } else if (i === 2) {
        return {
          name: gridHeader[i].title || data,
          selector: (row) =>
            row["status"] === 0 ? (
              <div onClick={() => acceptCard(row)}>
                <EventButton text="ACCEPT" />
              </div>
            ) : (
              "Accepted"
            ),
          sortable: false,
          width: '20%',
          center: 'yes',
        };
      } else {
        return {
          name: gridHeader[i].title || data,
          selector: (row) => row["name"],
          sortable: false,
          wrap: 'yes',
        };
      }
    });

    if (lastRow.isLastRow === true) {
      let appendLastRow = {};
      let { text } = lastRow.details;
      appendLastRow = {
        cell: (row) => (
          <div onClick={() => handleClick(row)}>
            <EventButton text={text} />
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        minWidth: "250px",
      };
      prepareColumn.push(appendLastRow);
    }
    let columnData = prepareColumn;
    return columnData;
  };

  const cb = (column, sortType) => {
    if (serverPagination) {
      callbacks.handleSorting({
        sort_column: column.selector,
        sort_order: sortType,
        page: page,
      });
    } else {
      return;
    }
  };
  const handlePageChange = (page) => {
    if (serverPagination) {
      setPage(page);
      callbacks.handlePagination(page);
    } else {
      return;
    }
  };
  const handleClick = (data) => {
    let { url } = lastRow.details;
    let frameUrl = null;

    if (data.id) {
      if (url.indexOf("{{userid}}") !== -1) {
        frameUrl = url.replace("{{userid}}", data.id);
      }
    }

    return navigate(frameUrl);
  };

  const acceptCard = (data) => {
    callbacks.handleCard(data.id);
  };

  return (
    <div className="grid-container table-responsive">
      {response && response.length && (
        <DataTable
          columns={columns}
          data={response}
          noHeader={true}
          striped={true}
          customStyles={customStyles}
          onSort={cb}
          // pagination /* pagination disabled */
          paginationServer={serverPagination}
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          paginationPerPage={perPage}
          paginationComponentOptions={{ noRowsPerPage: true }}
        />
      )}
      {response === null && <Spinner animation="grow" size="sm" />}
    </div>
  );
};

export default Grid;
