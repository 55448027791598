import { createSlice } from "@reduxjs/toolkit";
import fetch_json_action_creator from "../../app/utils/fetch";
import FEEDS from "../../feeds";

const initialState = {
  success: true,
  authenticated: true,
  profileData: [],
  postData: [],
  loading: true,
  hasErrors: false,
};

export const profileSlice = createSlice({
  name: "profileData",
  initialState,
  reducers: {
    getProfile: (state) => {
      state.loading = true;
    },
    getProfileSuccess: (state, { payload }) => {
      state.profileData = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getProfileFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    postProfileSuccess: (state, { payload }) => {
      state.postData = payload;
      state.loading = false;
      state.hasErrors = false;
    },
  },
});

export const { getProfile, getProfileSuccess, getProfileFailure, postProfileSuccess } =
  profileSlice.actions;
export const profileSelector = (state) => state.profileData;
export default profileSlice.reducer;

const getProfilePage = FEEDS.ROUTE.PROFILE.GET_PAGE_DEFINITION;

export function fetchProfile() {
  return async (dispatch) => {
    dispatch(getProfile());
    try {
      const response = await fetch_json_action_creator(getProfilePage);
      await dispatch(getProfileSuccess(response));
    } catch (error) {
      dispatch(getProfileFailure());
    }
  };
}
