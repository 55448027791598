import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginSelector, getLogout, getLogin } from "./loginSlice";
import _ from "lodash";
import FEEDS from "../../feeds";
import fetch_json_action_creator from "../../app/utils/fetch";
import GlobalSpinner from "../../app/utils/spinner";

const Logout = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector(loginSelector);

  useEffect(() => {
    async function Logout() {
      let getLogoutPage = FEEDS.ROUTE.COMMON.LOGOUT;
      dispatch(getLogin());
      let response = await fetch_json_action_creator(getLogoutPage);
      dispatch(getLogout(response));

      if (!_.isEmpty(response) && response.redirect) {
        window.location = "/login?s=" + btoa("logout");
      }
    }
    Logout();
  }, [dispatch]);

  return <>{loading && <GlobalSpinner />}</>;
};

export { Logout as default };
