import React from "react";
import TextBox from "../utils/form/text-box";
import Dropdown from "../utils/form/dropdown";

const FormBuilder = ({
  fieldSection,
  validation,
  handleChange,
  handleKeyUp,
}) => {
  return fieldSection.map((field) => {
    if (
      field.type === "text" ||
      field.type === "email" ||
      field.type === "number" ||
      field.type === "password" ||
      field.type === "hidden"
    ) {
      return (
        <TextBox
          key={field.key}
          config={field}
          validation={validation}
          editValue={field.value}
          handleChange={handleChange}
          handleKeyUp={handleKeyUp}
        />
      );
    } else if (field.type === "dropdown") {
      return (
        <Dropdown
          key={field.key}
          field={field}
          validation={validation}
          editValue={field.value}
        />
      );
    } else {
      return null;
    }
  });
};

export { FormBuilder as default };
