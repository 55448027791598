import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./header.scss";
import { CPSelector, fetchCP } from "../header/commonPageSlice";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import GlobalSpinner from "../../utils/spinner";
import GlobalAlert from "../../utils/alert/alert";
import CookieModal from "../modal/cookieModal";

const Header = () => {
  const dispatch = useDispatch();
  const { CPData, loading } = useSelector(CPSelector);
  const navigate = useNavigate();

  useEffect(() => {
    let a = window.location.href;
    if (!a.includes("/logout")) {
      dispatch(fetchCP());
    }
  }, [dispatch]);

  const routeChange = (path) => {
    return navigate(path);
  };

  return (
    <>
      {!_.isEmpty(CPData) && !_.isEmpty(CPData.data) ? (
        <header>
          <div className="row">
            <div className="col-12 header-top"></div>
            <div className="col-12">
              <div className="header-logo">
                <img
                  src={CPData.data.header.logo.img}
                  alt={CPData.data.header.logo.alt}
                  onClick={() => routeChange(CPData.data.header.logo.url)}
                />
              </div>
            </div>
            <div className="col-12 header-border"></div>
          </div>
        </header>
      ) : null}
      {loading && <GlobalSpinner />}
      {CPData && <GlobalAlert data={CPData} />}
      {CPData && <CookieModal data={CPData} />}
    </>
  );
};

export { Header as default };
