import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./login.scss";
import {
  loginSelector,
  fetchLogin,
  postLoginSuccess,
  getLogin,
} from "./loginSlice";
import _ from "lodash";
import { useForm } from "react-hook-form";
import FEEDS from "../../feeds";
import fetch_json_action_creator from "../../app/utils/fetch";
import { useNavigate } from "react-router-dom";
import FormBuilder from "../../app/common/formBuilder";
import Title from "../../app/common/title/title";
import GlobalSpinner from "../../app/utils/spinner";
import GlobalAlert from "../../app/utils/alert/alert";
import { decreaseRegisterStep } from "../register/registerSlice";

const Login = () => {
  const dispatch = useDispatch();
  const { loginData, postData, loading } = useSelector(loginSelector);
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(fetchLogin());
    dispatch(decreaseRegisterStep());
  }, [dispatch]);

  var postResponse = undefined;
  var postLoginPage = "";
  const onSubmit = async (data, e) => {
    postLoginPage = FEEDS.ROUTE.LOGIN.SUBMIT;
    dispatch(getLogin());
    postResponse = await fetch_json_action_creator(postLoginPage, "POST", data);
    dispatch(postLoginSuccess(postResponse));

    if (
      !_.isEmpty(postResponse) &&
      _.isEmpty(postResponse.validationErrors) &&
      postResponse.redirect
    ) {
      window.location = postResponse.redirect;
    }
  };

  if (!_.isEmpty(loginData) && !_.isEmpty(loginData.data)) {
    var registerButton = loginData.data.buttonList.register;
    var forgotPasswordButton = loginData.data.buttonList.forgotPassword;
    var submitButton = loginData.data.buttonList.submit;
    var formFields = loginData.data.formFields;
  }

  const routeChange = (path) => {
    return navigate(path);
  };

  return (
    <>
      {loginData && loginData.data ? (
        <div className="login-page">
          <GlobalAlert data={loginData} />
          {postData && <GlobalAlert data={postData} />}
          <Title
            data={{
              title: loginData.data.title,
              description: loginData.data.description,
            }}
          />

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mx-auto login-form">
              {formFields ? (
                <FormBuilder
                  fieldSection={formFields}
                  validation={{
                    register: register,
                    error: errors,
                    serverError: postData,
                  }}
                />
              ) : null}
            </div>

            <div className="text-center login-fp">
              {forgotPasswordButton && (
                <span
                  className="login-link"
                  onClick={() => routeChange(forgotPasswordButton.url)}
                >
                  {forgotPasswordButton.text}
                </span>
              )}
            </div>
            <div className="login-button text-center">
              {submitButton ? (
                <input
                  id={submitButton.key}
                  type={submitButton.type}
                  name={submitButton.key}
                  value={submitButton.text}
                  className="btn"
                />
              ) : null}
            </div>
            <div className="login-register-button text-center">
              {registerButton ? (
                <button
                  className="btn"
                  onClick={() => routeChange(registerButton.url)}
                >
                  {registerButton.text}
                </button>
              ) : null}
            </div>
          </form>
        </div>
      ) : null}
      {loading && <GlobalSpinner />}
    </>
  );
};

export { Login as default };
