import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Header from "./app/common/header/Header";
import Footer from "./app/common/footer/Footer";
import "./app.scss";
import Home from "./features/home/Home";
import Login from "./features/login/Login";
import Logout from "./features/login/Logout";
import Register from "./features/register/Register";
import Profile from "./features/profile/Profile";
import ForgotPassword from "./features/forgot-password/ForgotPassword";
import UpdatePassword from "./features/update-password/UpdatePassword";
import NotFound from "./features/not-found/NotFound";

function App() {
  return (
    <Router>
      <Container fluid className="p-0">
        <div className="row">
          <div className="col-12">
            <Header />
            <Routes>
              <Route exact path="/" element={<Login />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/home" element={<Home />} />
              <Route exact path="/register" element={<Register />} />
              <Route exact path="/profile" element={<Profile />} />
              <Route
                exact
                path="/forgot-password"
                element={<ForgotPassword />}
              />
              <Route
                exact
                path="/reset-password"
                element={<UpdatePassword />}
              />
              <Route exact path="/logout" element={<Logout />} />
              <Route exact path="*" element={<NotFound />} />
            </Routes>
            <Footer />
          </div>
        </div>
      </Container>
    </Router>
  );
}

export default App;
