export const guestRoutes = [
  "/",
  "/login",
  "/register",
  "/forgot-password",
  "/reset-password",
  "/logout",
];

export const adminRoutes = ["/home", "/profile", "/reset-password",];

export const getIndex = (data, keyValue) => {
  return data.findIndex(function (item) {
    return item.key === keyValue;
  });
};

export const getAllQueryString = () => {
  let url = window.location.href;
  let params = url.split("?");
  return params[1];
};

export const getQueryStringByName = (name) => {
  let urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
};

export const prepareGridData = (
  headers,
  source,
  paginationProps,
  lastRow,
  callbacks,
  staticPagination
) => {
  return {
    gridHeader: headers,
    gridData: source,
    paginationData: paginationProps,
    lastRow: lastRow,
    callbacks: callbacks,
    staticPagination:
      staticPagination && staticPagination === "static" ? true : false,
  };
};

export const scrollToTop = () => {  
  window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
}