import { createSlice } from "@reduxjs/toolkit";
import fetch_json_action_creator from "../../app/utils/fetch";
import FEEDS from "../../feeds";

const initialState = {
  success: true,
  authenticated: false,
  FPData: [],
  postData: [],
  loading: true,
  hasErrors: false,
};

export const FPSlice = createSlice({
  name: "FPData",
  initialState,
  reducers: {
    getFP: (state) => {
      state.loading = true;
    },
    getFPSuccess: (state, { payload }) => {
      state.FPData = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getFPFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    postFPSuccess: (state, { payload }) => {
      state.postData = payload;
      state.loading = false;
      state.hasErrors = false;
    },
  },
});

export const { getFP, getFPSuccess, getFPFailure, postFPSuccess } =
  FPSlice.actions;
export const FPSelector = (state) => state.FPData;
export default FPSlice.reducer;

const getFPPage = FEEDS.ROUTE.FORGOT_PASSWORD.GET_PAGE_DEFINITION;

export function fetchFP() {
  return async (dispatch) => {
    dispatch(getFP());
    try {
      const response = await fetch_json_action_creator(getFPPage);
      await dispatch(getFPSuccess(response));
    } catch (error) {
      dispatch(getFPFailure());
    }
  };
}
