import { createSlice } from "@reduxjs/toolkit";
import fetch_json_action_creator from "../../app/utils/fetch";
import FEEDS from "../../feeds";

const initialState = {
  success: true,
  authenticated: false,
  homeData: [],
  acceptData: [],
  acceptedCardId: null,
  loading: true,
  hasErrors: false,
};

export const homeSlice = createSlice({
  name: "homeData",
  initialState,
  reducers: {
    getHome: (state) => {
      state.loading = true;
    },
    getHomeSuccess: (state, { payload }) => {
      state.homeData = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getHomeFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    getHomeAcceptSuccess: (state, { payload }) => {
      state.acceptData = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    addAcceptedCardId: (state, { payload }) => {
      state.acceptedCardId = payload;
    },
    removeAcceptedCardId: (state) => {
      state.acceptedCardId = null;
    },
  },
});

export const {
  getHome,
  getHomeSuccess,
  getHomeFailure,
  getHomeAcceptSuccess,
  addAcceptedCardId,
  removeAcceptedCardId,
} = homeSlice.actions;

export const homeSelector = (state) => state.homeData;
export default homeSlice.reducer;

var getHomePage = FEEDS.ROUTE.HOME.GET_PAGE_DEFINITION;

export function fetchHome() {
  return async (dispatch) => {
    dispatch(getHome());
    try {
      const response = await fetch_json_action_creator(getHomePage);
      dispatch(getHomeSuccess(response));
    } catch (error) {
      dispatch(getHomeFailure());
    }
  };
}
