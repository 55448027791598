import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./forgotPassword.scss";
import {
  FPSelector,
  fetchFP,
  postFPSuccess,
  getFP,
} from "./forgotPasswordSlice";
import _ from "lodash";
import { useForm } from "react-hook-form";
import FEEDS from "../../feeds";
import fetch_json_action_creator from "../../app/utils/fetch";
import { useNavigate } from "react-router-dom";
import FormBuilder from "../../app/common/formBuilder";
import Title from "../../app/common/title/title";
import GlobalSpinner from "../../app/utils/spinner";
import GlobalAlert from "../../app/utils/alert/alert";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { FPData, postData, loading } = useSelector(FPSelector);
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(fetchFP());
  }, [dispatch]);

  var postResponse = undefined;
  var postFPPage = "";
  const onSubmit = async (data, e) => {
    postFPPage = FEEDS.ROUTE.FORGOT_PASSWORD.SUBMIT;
    dispatch(getFP());
    postResponse = await fetch_json_action_creator(postFPPage, "POST", data);
    dispatch(postFPSuccess(postResponse));

    if (
      postResponse &&
      _.isEmpty(postResponse.validationErrors) &&
      postResponse.successMsg
    ) {
      reset();
    }

    if (
      postResponse &&
      _.isEmpty(postResponse.validationErrors) &&
      postResponse.redirect
    ) {
      return navigate(postResponse.redirect);
    }
  };

  if (!_.isEmpty(FPData) && !_.isEmpty(FPData.data)) {
    var submitButton = FPData.data.buttonList.submit;
    var formFields = FPData.data.formFields;
    var loginMessage = FPData.data.loginMessage;
    var loginButton = FPData.data.buttonList.login;
  }

  const routeChange = (path) => {
    return navigate(path);
  };

  return (
    <>
      {FPData && FPData.data ? (
        <div className="fp-page">
          <GlobalAlert data={FPData} />
          <GlobalAlert data={postData} />
          <Title
            data={{
              title: FPData.data.title,
              description: FPData.data.description,
            }}
          />

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mx-auto fp-form">
              {formFields ? (
                <FormBuilder
                  fieldSection={formFields}
                  validation={{
                    register: register,
                    error: errors,
                    serverError: postData,
                  }}
                />
              ) : null}
            </div>

            <div className="submit-button text-center">
              {submitButton ? (
                <input
                  type={submitButton.type}
                  name={submitButton.key}
                  value={submitButton.text}
                  className="btn"
                />
              ) : null}
            </div>
          </form>

          <div className="bottom-login-link mx-auto">
            {loginMessage}
            {loginButton && (
              <button
                className="login-link"
                onClick={() => routeChange(loginButton.url)}
              >
                {" "}
                {loginButton.text}
              </button>
            )}
          </div>
        </div>
      ) : null}
      {loading && <GlobalSpinner />}
    </>
  );
};

export { ForgotPassword as default };
