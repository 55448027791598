import React from "react";
import "./dropdown.scss";

const Dropdown = ({ field, validation }) => {
  const { register, error, serverError } = validation || {};
  const items = field.items;

  var defaultValue = "";
  // eslint-disable-next-line
  for (const [key, item] of Object.entries(items)) {
    if (field.value === item.name) {
      defaultValue = field.value;
    }
  }
  return (
    <div className="mb-3 dropdown-input">
      <label>
        {field.label}
        {field.required !== "false" ? (
          <sup className="text-danger"> *</sup>
        ) : null}
      </label>

      <div className="select">
        <select
          id={field.key}
          className="form-select"
          name={field.key}
          {...register(
            field.key,
            field.required && field.required !== "false"
              ? { required: true }
              : {}
          )}
          defaultValue={defaultValue}
        >
          <option value="" className="empty-option">
            Select {field.label}
          </option>
          {items.map((item) => {
            return (
              <option
                key={item.name && item.name}
                value={item.name && item.name}
              >
                {item.value && item.value}
              </option>
            );
          })}
        </select>
      </div>

      {validation && error[field.key] && (
        <p className="text-danger">{field.label} is Required</p>
      )}
      {serverError &&
        serverError.validationErrors &&
        serverError.validationErrors[field.key] && (
          <p className="text-danger">
            {serverError.validationErrors[field.key]}
          </p>
        )}
    </div>
  );
};

export { Dropdown as default };
