import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./updatePassword.scss";
import {
  UPSelector,
  getUPSuccess,
  postUPSuccess,
  getUPFailure,
  getUP,
} from "./updatePasswordSlice";
import _ from "lodash";
import { useForm } from "react-hook-form";
import FEEDS from "../../feeds";
import fetch_json_action_creator from "../../app/utils/fetch";
import { useNavigate } from "react-router-dom";
import FormBuilder from "../../app/common/formBuilder";
import Title from "../../app/common/title/title";
import GlobalSpinner from "../../app/utils/spinner";
import GlobalAlert from "../../app/utils/alert/alert";
import { getQueryStringByName } from "../../app/utils/helpers";
import Alert from "react-bootstrap/Alert";

const UpdatePassword = () => {
  const dispatch = useDispatch();
  const { UPData, postData, loading } = useSelector(UPSelector);
  const navigate = useNavigate();
  const [display, setDisplay] = useState(true);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    let nonce = getQueryStringByName("nonce");
    let id = getQueryStringByName("id");
    let getUPPage = FEEDS.ROUTE.UPDATE_PASSWORD.GET_PAGE_DEFINITION;

    if (nonce && id && process.env.NODE_ENV !== "development") {
      getUPPage = getUPPage + "/" + nonce + "/" + id;
    }
    async function fetchUP() {
      dispatch(getUP());
      try {
        const response = await fetch_json_action_creator(getUPPage);
        dispatch(getUPSuccess(response));
      } catch (error) {
        dispatch(getUPFailure());
      }
    }
    fetchUP();
  }, [dispatch]); // eslint-disable-line

  const onSubmit = async (data, e) => {
    let nonce = getQueryStringByName("nonce");
    let id = getQueryStringByName("id");
    let postUPPage = FEEDS.ROUTE.UPDATE_PASSWORD.SUBMIT;
    if (nonce && id && process.env.NODE_ENV !== "development") {
      postUPPage = postUPPage + "/" + nonce + "/" + id;
    }
    dispatch(getUP());
    let postResponse = await fetch_json_action_creator(
      postUPPage,
      "POST",
      data
    );
    dispatch(postUPSuccess(postResponse));

    if (
      postResponse &&
      _.isEmpty(postResponse.validationErrors) &&
      postResponse.successMsg
    ) {
      reset();
    }

    if (
      !_.isEmpty(postResponse) &&
      _.isEmpty(postResponse.validationErrors) &&
      postResponse.redirect
    ) {
      return navigate(postResponse.redirect);
    }
  };

  if (!_.isEmpty(UPData) && !_.isEmpty(UPData.data)) {
    var submitButton = UPData.data.buttonList.submit;
    var loginButton = UPData.data.buttonList.login;
    var formFields = UPData.data.formFields;
  }

  const routeChange = (path) => {
    return navigate(path);
  };

  return (
    <>
      {UPData && !UPData.data && <GlobalAlert data={UPData} />}
      {UPData && UPData.data ? (
        <div className="up-page">
          <GlobalAlert data={UPData} />
          {postData && postData.authenticated === true && <GlobalAlert data={postData} />}
          {display && postData && postData.authenticated === false && postData.successMsg && (
            <div className="alert-message">
              <Alert
                variant="success"
                dismissible
                onClose={() => setDisplay(false)}
              >
                {UPData.data.loginMessageFirst && UPData.data.loginMessageFirst}{" "}
                <button
                  onClick={() => routeChange(loginButton.url)}
                  className="alert-link"
                >
                  {loginButton.text}
                </button>{" "}
                {UPData.data.loginMessageLast && UPData.data.loginMessageLast}
              </Alert>
            </div>
          )}
          <Title
            data={{
              title: UPData.data.title,
              description: UPData.data.description,
            }}
          />

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mx-auto up-form">
              {formFields ? (
                <FormBuilder
                  fieldSection={formFields}
                  validation={{
                    register: register,
                    error: errors,
                    serverError: postData,
                  }}
                />
              ) : null}
            </div>

            <div className="up-button text-center">
              {submitButton ? (
                <input
                  type={submitButton.type}
                  name={submitButton.key}
                  value={submitButton.text}
                  className="btn"
                />
              ) : null}
            </div>
          </form>
        </div>
      ) : null}
      {loading && <GlobalSpinner />}
    </>
  );
};

export { UpdatePassword as default };
