import { createSlice } from "@reduxjs/toolkit";
import fetch_json_action_creator from "../../app/utils/fetch";
import FEEDS from "../../feeds";

const initialState = {
  success: true,
  authenticated: false,
  loginData: [],
  logoutData: [],
  postData: [],
  loading: true,
  loader: false,
  hasErrors: false,
};

export const loginSlice = createSlice({
  name: "loginData",
  initialState,
  reducers: {
    getLogin: (state) => {
      state.loading = true;
    },
    getLogout: (state) => {
      state.loader = true;
    },
    getLoginSuccess: (state, { payload }) => {
      state.loginData = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getLoginFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    postLoginSuccess: (state, { payload }) => {
      state.postData = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    logoutSuccess: (state, { payload }) => {
      state.logoutData = payload;
      state.loading = false;
      state.hasErrors = false;
    },
  },
});

export const {
  getLogin,
  getLoginSuccess,
  getLoginFailure,
  postLoginSuccess,
  getLogout,
  logoutSuccess,
} = loginSlice.actions;
export const loginSelector = (state) => state.loginData;
export default loginSlice.reducer;

const getLoginPage = FEEDS.ROUTE.LOGIN.GET_PAGE_DEFINITION;

export function fetchLogin() {
  return async (dispatch) => {
    dispatch(getLogin());
    try {
      const response = await fetch_json_action_creator(getLoginPage);
      await dispatch(getLoginSuccess(response));
    } catch (error) {
      dispatch(getLoginFailure());
    }
  };
}
