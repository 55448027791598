import { createSlice } from "@reduxjs/toolkit";
import fetch_json_action_creator from "../../app/utils/fetch";
import FEEDS from "../../feeds";

const initialState = {
  success: true,
  authenticated: false,
  registerData: [],
  register2Data: [],
  register1UserData: [],
  postData: [],
  post2Data: [],
  currentStep: 1,
  loading: true,
  hasErrors: false,
};

export const registerSlice = createSlice({
  name: "registerData",
  initialState,
  reducers: {
    getRegister: (state) => {
      state.loading = true;
    },
    getRegisterSuccess: (state, { payload }) => {
      state.registerData = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getRegisterFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    postRegisterSuccess: (state, { payload }) => {
      state.postData = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getRegister2Success: (state, { payload }) => {
      state.register2Data = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getRegister2Failure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    postRegister2Success: (state, { payload }) => {
      state.post2Data = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    increaseRegisterStep: (state) => {
      state.currentStep = 2;
    },
    decreaseRegisterStep: (state) => {
      state.currentStep = 1;
    },
    storePostData: (state, { payload }) => {
      state.register1UserData = payload;
    },
  },
});

export const {
  getRegister,
  getRegisterSuccess,
  getRegisterFailure,
  postRegisterSuccess,
  getRegister2Success,
  getRegister2Failure,
  postRegister2Success,
  increaseRegisterStep,
  decreaseRegisterStep,
  storePostData,
} = registerSlice.actions;

export const registerSelector = (state) => state.registerData;
export default registerSlice.reducer;

var getRegisterPage = FEEDS.ROUTE.REGISTER.GET_PAGE_DEFINITION1;

export function fetchRegister() {
  return async (dispatch) => {
    dispatch(getRegister());
    try {
      const response = await fetch_json_action_creator(getRegisterPage);
      await dispatch(getRegisterSuccess(response));
    } catch (error) {
      dispatch(getRegisterFailure());
    }
  };
}
