const environment = process.env.NODE_ENV;

const FEEDS = {
  ROUTE: {
    LOGIN: {
      GET_PAGE_DEFINITION:
        environment === "development"
          ? "/mock/json/login/login.json"
          : "/api/login",

      SUBMIT:
        environment === "development"
          ? "/mock/json/login/submit.json"
          : "/api/login",
    },
    HOME: {
      GET_PAGE_DEFINITION:
        environment === "development"
          ? "/mock/json/home/home.json"
          : "/api/home",
      HOME_ACCEPT:
        environment === "development"
          ? "/mock/json/home/home_accept.json"
          : "/api/home-accept",
      GET_SSN:
        environment === "development" ? "/mock/json/home/ssn.json" : "/api/ssn",
      SSN_SUBMIT:
        environment === "development"
          ? "/mock/json/home/ssn_submit.json"
          : "/api/ssn",
    },
    REGISTER: {
      GET_PAGE_DEFINITION1:
        environment === "development"
          ? "/mock/json/register/register1.json"
          : "/api/register",

      SUBMIT1:
        environment === "development"
          ? "/mock/json/register/submit1.json"
          : "/api/register",
      GET_PAGE_DEFINITION2:
        environment === "development"
          ? "/mock/json/register/register2.json"
          : "/api/register-form-create",

      SUBMIT2:
        environment === "development"
          ? "/mock/json/register/submit2.json"
          : "/api/register-form",
    },
    PROFILE: {
      GET_PAGE_DEFINITION:
        environment === "development"
          ? "/mock/json/profile/profile.json"
          : "/api/profile",

      SUBMIT:
        environment === "development"
          ? "/mock/json/profile/submit.json"
          : "/api/profile",
    },
    FORGOT_PASSWORD: {
      GET_PAGE_DEFINITION:
        environment === "development"
          ? "/mock/json/forgot-password/forgot-password.json"
          : "/api/forgot-password",

      SUBMIT:
        environment === "development"
          ? "/mock/json/forgot-password/submit.json"
          : "/api/forgot-password",
    },
    UPDATE_PASSWORD: {
      GET_PAGE_DEFINITION:
        environment === "development"
          ? "/mock/json/update-password/update-password.json"
          : "/api/reset-password",

      SUBMIT:
        environment === "development"
          ? "/mock/json/update-password/submit.json"
          : "/api/reset-password",
    },
    COMMON: {
      HEADER_FOOTER: {
        GET_PAGE_DEFINITION:
          environment === "development"
            ? "/mock/json/common-page.json"
            : "/api/common",
      },
      COOKIE_NOTICE: {
        SUBMIT:
          environment === "development"
            ? "/mock/json/cookie-notice-submit.json"
            : "/api/cookie-accept",
      },
      LOGOUT:
        environment === "development"
          ? "/mock/json/logout.json"
          : "/api/logout",
    },
  },
};

export { FEEDS as default };
