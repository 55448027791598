import React from "react";
import "./text-box.scss";

const TextBox = ({
  config,
  validation,
  editValue,
  handleChange,
  handleKeyUp,
}) => {
  const { register, error, serverError } = validation || {};

  const {
    label,
    placeholder,
    required,
    readonly,
    key,
    hideLabel,
    type,
    value,
  } = config;
  let labelShow = hideLabel === "true" ? false : true;

  return (
    <div className="text-number-box mb-3">
      {label && labelShow && type !== "hidden" && (
        <label>
          {label}
          {required && required !== "false" ? (
            <sup className="text-danger"> *</sup>
          ) : null}
        </label>
      )}
      <input
        id={key}
        className="form-control"
        type={type}
        onChange={handleChange ? handleChange : null}
        onKeyUp={
          handleKeyUp
            ? (event) => {
                handleKeyUp(event.target.id, event.target.value);
              }
            : null
        }
        name={key}
        placeholder={placeholder ? placeholder : ""}
        defaultValue={value && value}
        {...register(
          key,
          required && required !== "false" ? { required: true } : {}
        )}
        disabled={readonly === true ? true : false}
      />

      {validation && error[key] && (
        <p className="text-danger">{label} is Required</p>
      )}
      {!error[key] &&
        serverError &&
        serverError.validationErrors &&
        serverError.validationErrors[key] && (
          <p className="text-danger">{serverError.validationErrors[key]}</p>
        )}
    </div>
  );
};

export default TextBox;
