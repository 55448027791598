import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './spinner.scss';

const GlobalSpinner = () => {
    return (
        <div className="spinner-overlay">
            <div className="spinner-wrapper">
                <Spinner animation="grow" size="lg" />
                <Spinner animation="grow" size="lg" />
                <Spinner animation="grow" size="lg" />
            </div>
        </div>
    )
};
export default GlobalSpinner;