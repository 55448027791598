import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import "./alert.scss";
import { getQueryStringByName } from "../../utils/helpers";
import _ from "lodash";

const GlobalAlert = (props) => {
  const [show, setShow] = useState(true);
  var type = props && props.type ? props.type : "danger";

  const isBase64 = (str) => {
    if (str === "" || str.trim() === "") {
      return false;
    }
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  };

  var msg = "";
  var errorStr = getQueryStringByName("e");
  if (
    !_.isEmpty(props) &&
    !_.isEmpty(props.data) &&
    !_.isEmpty(props.data.data) &&
    !_.isEmpty(props.data.data.errorMessages) &&
    errorStr
  ) {
    if (isBase64(errorStr)) {
      msg = props.data.data.errorMessages[atob(errorStr)];
      type = "danger";
    }
  }
  var successStr = getQueryStringByName("s");
  if (
    !_.isEmpty(props) &&
    !_.isEmpty(props.data) &&
    !_.isEmpty(props.data.data) &&
    !_.isEmpty(props.data.data.successMessages) &&
    successStr
  ) {
    if (isBase64(successStr)) {
      msg = props.data.data.successMessages[atob(successStr)];
      type = "success";
    }
  }

  if (props && props.data && props.data.pageError) {
    msg = props.data.pageError;
    type = "danger";
  } else if (props && props.data && props.data.successMsg) {
    msg = props.data.successMsg;
    type = "success";
  }

  return msg && show ? (
    <div className="alert-message">
      <Alert variant={type} dismissible onClose={() => setShow(false)}>
        {msg}
      </Alert>
    </div>
  ) : (
    <></>
  );
};
export default GlobalAlert;
