import React from "react";
import "./notFound.scss";

const NotFound = () => {
  return (
    <div className="not-found mx-auto">
      <div className="nf-headline">Whoops!</div>
      <div className="nf-description">404 Page Not Found</div>
    </div>
  );
};

export { NotFound as default };
