import { configureStore } from "@reduxjs/toolkit";
import commonPageSlice from "./common/header/commonPageSlice";
import loginSlice from "../features/login/loginSlice";
import homeSlice from "../features/home/homeSlice";
import registerSlice from "../features/register/registerSlice";
import register2Slice from "../features/register/register2Slice";
import profileSlice from "../features/profile/profileSlice";
import FPSlice from "../features/forgot-password/forgotPasswordSlice";
import UPSlice from "../features/update-password/updatePasswordSlice";
import ssnSlice from "../features/home/ssnSlice";

export const store = configureStore({
  reducer: {
    CPData: commonPageSlice,
    loginData: loginSlice,
    homeData: homeSlice,
    registerData: registerSlice,
    register2Data: register2Slice,
    profileData: profileSlice,
    FPData: FPSlice,
    UPData: UPSlice,
    loginSSNData: ssnSlice,
  },
});
