import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./profile.scss";
import {
  profileSelector,
  fetchProfile,
  postProfileSuccess,
  getProfile,
} from "./profileSlice";
import { useForm } from "react-hook-form";
import FormBuilder from "../../app/common/formBuilder";
import Title from "../../app/common/title/title";
import _ from "lodash";
import FEEDS from "../../feeds";
import { useNavigate } from "react-router-dom";
import fetch_json_action_creator from "../../app/utils/fetch";
import GlobalSpinner from "../../app/utils/spinner";
import GlobalAlert from "../../app/utils/alert/alert";
import { scrollToTop } from "../../app/utils/helpers";

const Profile = () => {
  const dispatch = useDispatch();
  const { profileData, postData, loading } = useSelector(profileSelector);
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  if (!_.isEmpty(profileData) && profileData.authenticated === false) {
    window.location = "/login?e=" + btoa("sessionTimedOut");
  }

  useEffect(() => {
    dispatch(fetchProfile());
  }, [dispatch]);

  var postProfilePage = "";
  const onSubmit = async (data, e) => {
    postProfilePage = FEEDS.ROUTE.PROFILE.SUBMIT;
    dispatch(getProfile());
    const postResponse = await fetch_json_action_creator(
      postProfilePage,
      "POST",
      data
    );
    if (postResponse && !_.isEmpty(postResponse) && postResponse.authenticated === false) {
      window.location = "/login?e=" + btoa("sessionTimedOut");
    }
    dispatch(postProfileSuccess(postResponse));

    if (postResponse && _.isEmpty(postResponse.validationErrors) && postResponse.successMsg) {
      scrollToTop();
    }
    if (postResponse && _.isEmpty(postResponse.validationErrors) && postResponse.redirect) {
      return navigate(postResponse.redirect);
    }
  };

  const routeChange = (path) => {
    return navigate(path);
  };

  if (!_.isEmpty(profileData) && !_.isEmpty(profileData.data)) {
    var formFields = profileData.data.formFields.set1;
    var formFields2 = profileData.data.formFields.set2;
    var passwordInfo = profileData.data.passwordInfo;
    var submitButton = profileData.data.buttonList.submit;
    var backButton = profileData.data.buttonList.back;
  }

  return (
    <>
      {profileData && profileData.data ? (
        <div className="profile-page">
          <GlobalAlert data={profileData} />
          <GlobalAlert data={postData} />
          <Title
            data={{
              title: profileData.data.title,
              description: profileData.data.description,
            }}
          />
          <div className="profile-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mx-auto form-container">
                {formFields ? (
                  <FormBuilder
                    fieldSection={formFields}
                    validation={{
                      register: register,
                      error: errors,
                      serverError: postData,
                    }}
                  />
                ) : null}
              </div>
              {passwordInfo && (
                <div className="form-info mx-auto">{passwordInfo}</div>
              )}
              <div className="mx-auto form-container form-set2">
                {formFields2 ? (
                  <FormBuilder
                    fieldSection={formFields2}
                    validation={{
                      register: register,
                      error: errors,
                      serverError: postData,
                    }}
                  />
                ) : null}
              </div>
              <div className="form-container mx-auto">
                <div className="row">
                  <div className="col-6 profile-back-button">
                    {backButton ? (
                      <button
                        className="btn"
                        onClick={() => routeChange(backButton.url)}
                      >
                        {backButton.text}
                      </button>
                    ) : null}
                  </div>
                  <div className="col-6 profile-button">
                    {submitButton ? (
                      <input
                        type={submitButton.type}
                        name={submitButton.key}
                        value={submitButton.text}
                        className="btn"
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : null}
      {loading && <GlobalSpinner />}
    </>
  );
};

export { Profile as default };
