import { createSlice } from "@reduxjs/toolkit";
import fetch_json_action_creator from "../../utils/fetch";
import FEEDS from "../../../feeds";

const initialState = {
  success: true,
  authenticated: false,
  CPData: [],
  loading: true,
  hasErrors: false,
};

export const commonPageSlice = createSlice({
  name: "commonPageData",
  initialState,
  reducers: {
    getCP: (state) => {
      state.loading = true;
    },
    getCPSuccess: (state, { payload }) => {
      state.CPData = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getCPFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    CPSuccess: (state) => {
      state.loading = false;
    },
  },
});

export const { getCP, getCPSuccess, getCPFailure, CPSuccess } =
  commonPageSlice.actions;
export const CPSelector = (state) => state.CPData;
export default commonPageSlice.reducer;

const feedPath = FEEDS.ROUTE.COMMON.HEADER_FOOTER.GET_PAGE_DEFINITION;

export function fetchCP() {
  return async (dispatch) => {
    dispatch(getCP());
    try {
      const response = await fetch_json_action_creator(feedPath);
      await dispatch(getCPSuccess(response));
    } catch (error) {
      dispatch(getCPFailure());
    }
  };
}
