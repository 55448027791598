import { createSlice } from "@reduxjs/toolkit";
import fetch_json_action_creator from "../../app/utils/fetch";
import FEEDS from "../../feeds";

const initialState = {
  success: true,
  authenticated: false,
  loginSSNData: [],
  postData: [],
  loading: true,
  hasErrors: false,
  ssnInput: null,
  ssnInputConfirm: null,
};

export const login1Slice = createSlice({
  name: "loginSSNData",
  initialState,
  reducers: {
    getLogin: (state) => {
      state.loading = true;
    },
    getLoginSuccess: (state, { payload }) => {
      state.loginSSNData = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getLoginFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    postLoginSuccess: (state, { payload }) => {
      state.postData = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    addSSNInput: (state, { payload }) => {
      state.ssnInput = payload;
    },
    addSSNInputConfirm: (state, { payload }) => {
      state.ssnInputConfirm = payload;
    },
  },
});

export const {
  getLogin,
  getLoginSuccess,
  getLoginFailure,
  postLoginSuccess,
  addSSNInput,
  addSSNInputConfirm,
} = login1Slice.actions;
export const login1Selector = (state) => state.loginSSNData;
export default login1Slice.reducer;

const getLoginPage = FEEDS.ROUTE.HOME.GET_SSN;

export function fetchLogin() {
  return async (dispatch) => {
    dispatch(getLogin());
    try {
      const response = await fetch_json_action_creator(getLoginPage);
      await dispatch(getLoginSuccess(response));
    } catch (error) {
      dispatch(getLoginFailure());
    }
  };
}
