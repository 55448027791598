import React from "react";
import "./title.scss";

const Title = (data) => {
  return (
    <div className="title-container">
      {data.data.title ? (
        <h2 className="headline">{data.data.title}</h2>
      ) : null}
      {data.data.description ? (
        <p className="description">
          {data.data.description}
        </p>
      ) : null}
    </div>
  );
};

export { Title as default };
