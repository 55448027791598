import React from "react";
import { useSelector } from "react-redux";
import { registerSelector } from "./registerSlice";
import Register1 from "./Register1";
import Register2 from "./Register2";

const Register = () => {
  const { currentStep } = useSelector(registerSelector);

  return currentStep === 1 ? <Register1 /> : <Register2 />;
};

export { Register as default };
