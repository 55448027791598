import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  success: true,
  authenticated: false,
  UPData: [],
  postData: [],
  loading: true,
  hasErrors: false,
};

export const UPSlice = createSlice({
  name: "UPData",
  initialState,
  reducers: {
    getUP: (state) => {
      state.loading = true;
    },
    getUPSuccess: (state, { payload }) => {
      state.UPData = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getUPFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    postUPSuccess: (state, { payload }) => {
      state.postData = payload;
      state.loading = false;
      state.hasErrors = false;
    },
  },
});

export const { getUP, getUPSuccess, getUPFailure, postUPSuccess } =
  UPSlice.actions;
export const UPSelector = (state) => state.UPData;
export default UPSlice.reducer;
